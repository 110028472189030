import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='0 0 1280 480'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
		<path 
			fill="#fff" d="M1197.87,353.21c-20.94,0-32.28-12.3-32.28-35.14V209.21h49.09L1228,162.54h-62.37V112.46l-54.51,15.75V319.38c0,51.85,29.22,79.08,80.25,79.08,19.62,0,39.25-5.27,52.33-15.81l-15.26-39.1C1220.11,350.14,1209.21,353.21,1197.87,353.21Z">
		</path>
		<path 
			fill="#fff" d="M440.81,257.88V395.39H397.54l-8.19-28.56C376.27,387,351,398.46,316.08,398.46c-53.21,0-86.79-29.43-86.79-70.29,0-39.1,26.17-69.85,96.82-69.85H386.3V254.8c0-32.07-19.19-51-58-51-26.17,0-53.21,8.78-70.65,23.28l-21.37-40c24.86-19.33,61.06-29,98.56-29C402,158.15,440.81,190.22,440.81,257.88ZM386.3,322V294.78H330c-37.08,0-47.11,14.06-47.11,31.19,0,19.77,16.58,32.51,44.49,32.51C354,358.48,377.14,346.18,386.3,322Z">
		</path>
		<path 
			fill="#fff" d="M806.69,295.66H624c6.55,34.27,34.89,56.23,75.45,56.23,26.17,0,46.67-8.34,63.24-25.48l29.22,33.83c-20.93,25-53.64,38.22-93.76,38.22-78.07,0-128.66-50.52-128.66-120.37s51-119.94,120.37-119.94c68,0,117.76,47.88,117.76,121.25C807.57,283.8,807.13,290.39,806.69,295.66ZM623.52,259.19H755.67c-4.37-33.82-30.1-57.11-65.86-57.11C654.48,202.08,628.75,224.93,623.52,259.19Z">
		</path>
		<polygon 
			fill="#fff" points="481.73 97.28 481.73 395.39 536.24 395.39 536.24 81.53 481.73 97.28">
		</polygon>
		<polygon fill="#00afce" points="215.34 81.53 202.09 128.07 295.67 128.07 308.91 81.53 215.34 81.53"></polygon>
		<polygon fill="#fff" points="49.59 81.53 36.34 128.07 133.78 128.07 133.78 395.39 188.38 395.39 188.38 81.53 49.59 81.53"></polygon>
		<path fill="#fff" d="M972.91,158.15c-34,0-62.37,11.42-80.25,32.95l-7.32-25.52-44.58,12.88V395.39h54.52V276.77c0-47,26.16-70.74,65-70.74,34.89,0,55.39,20.21,55.39,62V395.39h54.52V261C1070.17,190.22,1028.73,158.15,972.91,158.15Z">
		</path>
		<rect fill="none" width="1280" height="480">
		</rect>
		</svg>

	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
